import React from 'react';
import Modal from 'react-modal';
import styled, { createGlobalStyle } from 'styled-components';

import { colors, mediaQueries } from 'variables';

const ModalStyles = createGlobalStyle`
  .Modal {
    display: flex;
    flex-direction: column;
    margin: 16px;
    width: 100%;
    max-width: 666px;
    padding: 16px;
    padding-top: 8px;
    border: none;
    box-shadow: 0 0 17px 1px rgba(0, 0, 0, 0.32);
    background-color: ${colors.background};
    color: ${colors.secondary};
    outline: none;
    text-align: left;
    font-family: 'Associate Mono';
    box-sizing: border-box;
  }

  .Overlay {
    z-index: 999;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const Header = styled.div`
  display: flex;
  padding-bottom: 4px;
`;

const CloseButton = styled.button`
  border: none;
  font-family: 'Associate Mono Bold';
  width: 20px;
  font-size: 20px;
  margin-left: auto;
  background-color: transparent;
  color: ${colors.secondary};
  cursor: pointer;

  :hover {
    color: white;
  }
`;

const TextArea = styled.textarea`
  background-color: transparent;
  border: 1px solid ${colors.secondary};
  padding: 8px;
  height: 150px;
  color: white;
  resize: none;
`;

const Button = styled.button`
  margin-top: 24px;
  padding: 8px;
  border: 1px solid ${colors.secondary};
  color: ${colors.secondary};
  background-color: transparent;
  width: 150px;
  cursor: pointer;

  :hover {
    color: white;
    border-color: white;
  }
`;

const ProjectName = styled.span`
  color: white;
`;

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  saveComment: (text: string, projectId: number) => Promise<void>;
  projectId: number;
  projectName: string;
  initialValue: string | null;
}

export const AddCommentModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  saveComment,
  projectId,
  projectName,
  initialValue,
}) => {
  const [text, setText] = React.useState(initialValue || '');
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const onSave = async () => {
    setIsSubmitting(true);
    try {
      await saveComment(text, projectId);
      setIsSubmitting(false);
      closeModal();
    } catch {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} className="Modal" overlayClassName="Overlay" onRequestClose={closeModal}>
      <ModalStyles />
      <Header>
        <p>
          Add comment to <ProjectName>{projectName}</ProjectName>
        </p>
        <CloseButton onClick={closeModal}>X</CloseButton>
      </Header>

      <TextArea placeholder="Please type here..." value={text} onChange={e => setText(e.target.value)} />

      <Button onClick={onSave} disabled={isSubmitting}>
        Save{' '}
        {isSubmitting && (
          <span>
            <i className="fa fa-circle-o-notch fa-spin" />
          </span>
        )}
      </Button>
    </Modal>
  );
};
