import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';

import { Footer } from 'components/common/Footer';
import { Header } from 'components/common/Header';
import LoggedIn from 'components/common/LoggedIn';
import { Login } from 'pages/Auth/Login';
import { Homepage } from 'pages/Homepage';
import { AuthContextProvider } from 'contexts/AuthContext';
import { ProjectList } from 'pages/project/ProjectList';
import { ProjectDetails } from 'pages/project/ProjectDetails';
import { colors, routes } from 'variables';

import AssociateMonoLightWoff from './assets/fonts/AssociateMono-Light.woff';
import AssociateMonoLightWoff2 from './assets/fonts/AssociateMono-Light.woff2';
import AssociateMonoMediumWoff from './assets/fonts/AssociateMono-Medium.woff';
import AssociateMonoMediumWoff2 from './assets/fonts/AssociateMono-Medium.woff2';
import { JuryList } from 'pages/jury/jury';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Associate Mono';
    src: url(${AssociateMonoLightWoff}) format('woff'), url(${AssociateMonoLightWoff2}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  

  @font-face {
    font-family: 'Associate Mono Bold';
    src: url(${AssociateMonoMediumWoff}) format('woff'), url(${AssociateMonoMediumWoff2}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
  html {
    background: ${colors.background};
  }

  #root {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 20.16px;
    font-family: Associate Mono, sans-serif;
    background: #000000;
    color: ${colors.primary};
  }

  main {
    max-width: 1440px;
    margin: 0 auto;
    box-sizing: border-box;
  }
  
  h2 {
    font-family: Associate Mono Bold, sans-serif;
    font-size: 24px;
    font-weight: normal;
    line-height: 30.24px;
  }

  h3 {
    font-family: Associate Mono Bold, sans-serif;
    font-size: 20px;
    font-weight: normal;
    line-height: 25.2px;
  }

  .Toastify__toast-body {
    padding-right: 20px;
  }
`;

export const App: React.FC = () => (
  <BrowserRouter>
    <AuthContextProvider>
      <GlobalStyle />
      <Header />

      <Switch>
        <Route path={routes.login} exact>
          <Login />
        </Route>

        <LoggedIn>
          <Route path={routes.home} exact>
            <Homepage />
          </Route>

          <Route path={routes.projects} exact>
            <ProjectList />
          </Route>

          <Route path={`${routes.projects}/:id/:category`}>
            <ProjectDetails />
          </Route>

          <Route path={routes.jury} exact>
            <JuryList />
          </Route>
        </LoggedIn>

        <Redirect to="/" />
      </Switch>

      <Footer />
    </AuthContextProvider>
  </BrowserRouter>
);
