import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";

import { apiUrls, routes } from "variables";
import { PrimaryButton } from "components/common/Buttons";
import { Input } from "components/form/Input";
import {
  initialValues,
  LoginRequestValues,
  validationSchema,
} from "helpers/loginForm";
import { apiClient } from "helpers/APIClient";
import { AuthContext, loginUser } from "contexts/AuthContext";
import { toastSettings } from "helpers/toastSettings";

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  margin: auto;
  padding: 0 32px;
`;

const Title = styled.h2`
  margin: 42px 0;

  text-align: center;
`;

const StyledForm = styled(Form)`
  width: 100%;
  height: 100%;
  max-width: 533px;
  display: flex;
  flex-direction: column;
`;

const StyledButton = styled(PrimaryButton)`
  width: 213px;
  margin-top: 56px;
  align-self: center;
`;

const ForgotPassword = styled.p`
  margin-top: -18px;
  cursor: pointer;
`;

export const Login: React.FC = () => {
  const [isFetching, setIsFetching] = React.useState(false);

  const history = useHistory();
  const { dispatch } = React.useContext(AuthContext);

  const onSubmit = (values: LoginRequestValues) => {
    setIsFetching(true);

    apiClient
      .post(apiUrls.login, values)
      .then((res) => dispatch(loginUser(res.data)))
      .then(() => {
        localStorage.clear();
        history.push(routes.home);
      })
      .catch((err) => {
        err.response.data.errors.forEach(({ message }: { message: string }) =>
          toast(message, toastSettings)
        );
        setIsFetching(false);
      });
  };

  return (
    <Wrapper>
      <Title>Login to Jury panel</Title>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, handleChange }) => (
          <StyledForm>
            <Input
              label="Email"
              name="email"
              value={values.email}
              onChange={handleChange}
              error={errors.email}
              touched={touched.email}
            />

            <Input
              label="Password"
              name="password"
              type="password"
              value={values.password}
              onChange={handleChange}
              error={errors.password}
              touched={touched.password}
            />
            {/* <ForgotPassword onClick={() => history.push('/password-recovery')}>
              Forgot password?
            </ForgotPassword> */}

            <StyledButton type="submit" disabled={isFetching}>
              Login
              {isFetching && (
                <span>
                  <i className="fa fa-circle-o-notch fa-spin" />
                </span>
              )}
            </StyledButton>
          </StyledForm>
        )}
      </Formik>
    </Wrapper>
  );
};
