import React from 'react';
import styled from 'styled-components';
import { apiUrls, colors, mediaBase, mediaQueries, awardCategories } from 'variables';
import { indexOf, orderBy } from 'lodash';

import { AuthContext } from 'contexts/AuthContext';
import { apiClient } from 'helpers/APIClient';
import { AwardCategory, ProjectListEntry } from 'types';
import { ProjectCard } from 'components/project/ProjectCard';
import { toast } from 'react-toastify';
import { toastSettings } from 'helpers/toastSettings';
import { Loader } from 'components/common/Loader';
import StyledSelect from 'components/common/Select';

const Wrapper = styled.main`
  width: 100%;
  padding: 36px 25px;

  ${mediaQueries.lg} {
    padding: 36px 50px;
  }

  ${mediaQueries.xl} {
    padding: 36px 88px;
  }
`;

const Categories = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  width: 100%;

  ${mediaQueries.lg} {
    flex-direction: row;
    align-items: center;
  }
`;

const GroupFilters = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  width: 100%;
  margin-top: 36px;
  ${mediaQueries.lg} {
    margin-top: 0;
    flex-direction: row;
    align-items: center;
  }
`;

const Category = styled.span<{ active: boolean }>`
  margin-bottom: 24px;

  font-size: 18px;
  cursor: pointer;
  color: ${props => (props.active ? 'white' : colors.secondary)};
  ${mediaQueries.lg} {
    margin-bottom: 0;
    margin-right: 32px;
  }
`;

const GroupFilter = styled.span<{ active: boolean }>`
  margin-bottom: 24px;

  font-size: 16px;
  cursor: pointer;
  color: ${props => (props.active ? 'white' : colors.secondary)};
  ${mediaQueries.lg} {
    margin-right: 32px;
  }
`;

const SelectWrapper = styled.span`
  width: 250px;
  margin-top: 8px;
  margin-left: -10px;
  ${mediaQueries.lg} {
    margin-top: 0;
    margin-left: auto;
  }
`;

const ProjectGrid = styled.div`
  display: grid;
  justify-content: center;
  justify-items: center;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 16px;
  column-gap: 16px;

  ${mediaQueries.sm} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${mediaQueries.md} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${mediaQueries.lg} {
    grid-template-columns: repeat(4, 1fr);
  }

  ${mediaQueries.xl} {
    grid-template-columns: repeat(5, 1fr);
  }

  ${mediaQueries.xxl} {
    grid-template-columns: repeat(6, 1fr);
  }
`;

const sortingOptions = [
  { label: 'Alphabetical', value: 'alphabetical' },
  { label: 'Professional/Student', value: 'profstud' },
  { label: 'Personal high score', value: 'personalScore' },
  // { label: 'Global high score', value: 'globalScore' },
];

const categorySort = (a: ProjectListEntry, b: ProjectListEntry): number => {
  const categoryArr = [
    'INDIVIDUAL_STUDENT',
    'TEAM_OF_STUDENTS',
    'INDIVIDUAL_PROFESSIONAL',
    'COMPANY_GREATER_THAN_10_EMPLOYEES',
    'COMPANY_GREATER_11_100_EMPLOYEES',
    'COMPANY_GREATER_THAN_100_EMPLOYEES',
  ];

  if (a.participantCategory === b.participantCategory) {
    if (a.projectName.toLocaleUpperCase() < b.projectName.toLocaleUpperCase()) {
      return -1;
    }
    return 1;
  }

  return indexOf(categoryArr, b.participantCategory) - indexOf(categoryArr, a.participantCategory);
};

const sortProjects = (projects: ProjectListEntry[], sortingOption: string): ProjectListEntry[] => {
  switch (sortingOption) {
    case 'alphabetical':
      return orderBy(projects, project => project.projectName?.toLowerCase(), ['asc']);
    case 'profstud':
      return [...projects].sort(categorySort);
    case 'personalScore':
      const sorted = sortProjects(projects, 'alphabetical');
      return orderBy(sorted, project => project.score || 0, ['desc']);
    default:
      return projects;
  }
};

const extraGroups = [
  { label: 'All', value: null },
  { label: 'Group 1', value: 'GROUP_1' },
  { label: 'Group 2', value: 'GROUP_2' },
  { label: 'Group 3', value: 'GROUP_3' },
];

export const ProjectList = (): JSX.Element => {
  const [activeCategory, setActiveCategory] = React.useState(
    (localStorage.getItem('category') as AwardCategory) || awardCategories[0].key,
  );
  const { state } = React.useContext(AuthContext);
  const [projects, setProjects] = React.useState<ProjectListEntry[]>([]);
  const [isFetching, setIsFetching] = React.useState(false);

  const storageSorting = localStorage.getItem('sorting');
  const defaultSorting =
    storageSorting !== null
      ? (JSON.parse(storageSorting) as { label: string; value: string })
      : sortingOptions[2];
  const [sorting, setSorting] = React.useState(defaultSorting);
  const [publishTotalScore, setPublishTotalScore] = React.useState(false);
  const [groupFilter, setGroupFilter] = React.useState<string | null>(localStorage.getItem('group'));

  const refetch = () => {
    apiClient
      .get(apiUrls.projects(state.user.uid, activeCategory))
      .then(({ data }) => data.filter((project: any) => project.projectEdition === '2024'))
      .then(sortedProjects => setProjects(sortedProjects));
  };

  const saveVote = async (score: string | null, projectId: number) => {
    try {
      await apiClient.put(apiUrls.saveScore(state.user.uid, projectId.toString()), {
        score,
        category: activeCategory,
      });
      refetch();
    } catch {
      toast('Failed to save vote', toastSettings);
    }
  };

  React.useEffect(() => {
    setIsFetching(true);

    Promise.all([
      apiClient
        .get(apiUrls.projects(state.user.uid, activeCategory))
        .then(({ data }) => data.filter((project: any) => project.projectEdition === '2024'))
        .then(sortedProjects => setProjects(sortedProjects))
        .catch(() => toast('Failed to fetch projects', toastSettings)),
      apiClient
        .get(apiUrls.sites)
        .then(({ data }) => setPublishTotalScore(data.publishTotalScore))
        .catch(() => toast('Failed to fetch settings', toastSettings)),
    ]).then(() => setIsFetching(false));
  }, [activeCategory]);

  const saveComment = async (comment: string, projectId: number) => {
    if (comment === '') {
      return;
    }
    try {
      await apiClient.post(apiUrls.postComment(state.user.uid, projectId), {
        comment,
      });
      setProjects(
        projects.map(project =>
          project.id === projectId ? { ...project, comments: project.comments + 1 } : project,
        ),
      );
    } catch {
      toast('Failed to save comment', toastSettings);
    }
  };

  const filteredProjects = projects.filter(project => project.awardCategory.includes(activeCategory));
  const sortedProjects = sortProjects(
    filteredProjects,
    sorting.value,
  ).sort(({ deaScore: a }, { deaScore: b }) => (a === b ? 0 : a ? -1 : 1));

  const filteredAndSortedProjects = groupFilter
    ? sortedProjects.filter(project => project.labels.some(label => label.deaLabel === groupFilter))
    : sortedProjects;

  return (
    <Wrapper>
      <Categories>
        {awardCategories.map(category => (
          <Category
            key={category.key}
            active={activeCategory === category.key}
            onClick={() => {
              setActiveCategory(category.key);
              localStorage.setItem('category', category.key);
            }}
          >
            {category.label}
            {state.user.juryAssignedProjectCategories.includes(category.key) ? '*' : ''}
          </Category>
        ))}
        <SelectWrapper>
          <StyledSelect
            options={sortingOptions}
            value={sorting}
            onChange={(value: { label: string; value: string }) => {
              setSorting(value);
              localStorage.setItem('sorting', JSON.stringify(value));
            }}
          />
        </SelectWrapper>
      </Categories>

      <GroupFilters>
        {extraGroups.map(group => (
          <GroupFilter
            key={group.label}
            active={groupFilter === group.value}
            onClick={() => {
              setGroupFilter(group.value);
              if (typeof group.value === 'string') localStorage.setItem('group', group.value);
            }}
          >
            {group.label}
          </GroupFilter>
        ))}
      </GroupFilters>

      {isFetching ? (
        <Loader />
      ) : filteredAndSortedProjects.length ? (
        <ProjectGrid>
          {filteredAndSortedProjects.map(project => (
            <ProjectCard
              key={project.id}
              projectImage={`${mediaBase}compressed-x-jury1/${project?.images[0]?.image}`}
              projectId={project.id}
              projectName={project.projectName}
              rating={project.score}
              commented={project.comments > 0}
              saveComment={saveComment}
              projectCategory={activeCategory}
              saveVote={saveVote}
              comment={project.commentContent}
              deaScore={project.deaScore}
              publishedTotalScore={publishTotalScore}
              totalScore={project.total}
              labels={project.labels.filter(label => label.deaLabelCategory === activeCategory)}
            />
          ))}
        </ProjectGrid>
      ) : (
        <h2>No projects in this category</h2>
      )}
    </Wrapper>
  );
};
