import React from 'react';
import styled from 'styled-components';
import Rate from 'rc-rate';
import 'rc-rate/assets/index.css';

import { apiUrls, colors, mediaBase, mediaQueries } from 'variables';
import { PrimaryButton } from 'components/common/Buttons';
import { useHistory, useParams } from 'react-router-dom';
import { Loader } from 'components/common/Loader';
import { apiClient } from 'helpers/APIClient';
import { AuthContext } from 'contexts/AuthContext';
import { toast } from 'react-toastify';
import { toastSettings } from 'helpers/toastSettings';
import { AwardCategory, ProjectSingle } from 'types';
import { prettifyProjectId } from 'helpers/prettifyProjectId';
import { getParticipantCategory } from 'helpers/getParticipantCategory';
import { PhotoModal } from 'components/modals/PhotoModal';

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 36px 25px;

  ${mediaQueries.lg} {
    padding: 36px 50px;
    flex-direction: row;
    justify-content: space-between;
  }

  ${mediaQueries.xl} {
    padding: 36px 88px;
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${mediaQueries.lg} {
    width: 50%;
    padding-right: 54px;
  }
`;

const Images = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 48px;

  ${mediaQueries.lg} {
    width: 50%;
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 16px;
  cursor: pointer;
`;

const PrimaryText = styled.p`
  margin: 2px 0;
  font-size: 18px;
`;

const SecondaryText = styled.p`
  color: ${colors.secondary};
  font-size: 18px;
  margin: 0;
`;

const RatingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledRate = styled(Rate)<{ size: number }>`
  &.rc-rate {
    font-size: ${({ size }) => size}px !important;
  }
`;

const Rating = styled.p`
  margin: 8px 0 0;
  font-size: 28px;
  padding-top: 6px;
`;

const Annotation = styled.p`
  margin-top: 32px;
  margin-bottom: 0;
  color: ${colors.secondary};
`;

const TextArea = styled.textarea`
  background-color: transparent;
  border: 1px solid ${colors.secondary};
  padding: 8px;
  height: 150px;
  color: white;
  resize: none;
  margin-top: 32px;
`;

const TextAreaButton = styled.button`
  margin-top: 24px;
  padding: 8px;
  border: 1px solid ${colors.secondary};
  color: ${colors.secondary};
  background-color: transparent;
  width: 150px;
  cursor: pointer;

  :hover {
    color: white;
    border-color: white;
  }
`;

const DescriptionWrapper = styled.div`
  color: ${colors.secondary};
  margin: 52px 0;
`;

const DetailTitle = styled.span`
  color: white;
`;

const NextButton = styled(PrimaryButton)`
  margin-bottom: 16px;
  background-color: transparent;
  color: white;
  border: 2px solid white;
  font-size: 20px;
  padding: 24px 16px;
`;

const NavigationButtonsWrapper = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Description = styled.p`
  margin-top: 0;
`;

const Comment = styled.p`
  word-break: break-word;
`;

interface Project extends ProjectSingle {
  comment: string;
  previousProjectId: string;
}

export const ProjectDetails = (): JSX.Element => {
  const history = useHistory();
  const params = useParams<{ id: string | undefined; category: string | undefined }>();
  const projectId = params.id;
  const category = params.category;

  const { state } = React.useContext(AuthContext);
  const [project, setProject] = React.useState<Project | null>(null);
  const [score, setScore] = React.useState(0);
  const [isSubmittingScore, setIsSubmittingScore] = React.useState(false);
  const [commentValue, setCommentValue] = React.useState('');
  const [isFetchingComment, setIsFetchingComment] = React.useState(false);
  const [modalPicture, setModalPicture] = React.useState<string | null>(null);
  const [isEditingComment, setIsEditingComment] = React.useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const slxMember = state.user.slxMember;

  const refresh = async () => {
    await apiClient
      .get(`${apiUrls.projectDetails(state.user.uid, projectId, category)}`)
      .then(({ data }) => setProject(data));
  };

  const saveScore = async (score: string | null) => {
    setIsSubmittingScore(true);
    try {
      await apiClient.put(apiUrls.saveScore(state.user.uid, projectId), { score, category });
      await refresh();
    } catch {
      toast('Failed to save vote', toastSettings);
    } finally {
      setIsSubmittingScore(false);
    }
  };

  const saveComment = async (comment: string) => {
    if (!project) {
      return;
    }

    setIsFetchingComment(true);
    try {
      await apiClient.post(apiUrls.postComment(state.user.uid, project.id), { comment, category });
      await refresh();

      setIsEditingComment(false);
    } catch {
      toast('Failed to save comment', toastSettings);
    } finally {
      setIsFetchingComment(false);
    }
  };

  React.useEffect(() => {
    apiClient
      .get(`${apiUrls.projectDetails(state.user.uid, projectId, category)}`)
      .then(({ data }) => setProject(data))
      .catch(() => toast('Unable to get project details', toastSettings));
  }, []);

  React.useEffect(() => {
    if (!project) return;

    setScore(project.score || 0);

    project.comment && setCommentValue(project.comment);
  }, [project]);

  if (!project) return <Loader />;

  return (
    <Wrapper>
      <Details>
        <SecondaryText>{prettifyProjectId(project.id)}</SecondaryText>
        <PrimaryText>{project.projectName}</PrimaryText>

        {slxMember ? (
          <div />
        ) : (
          <>
            {' '}
            <Annotation>Add vote</Annotation>
            <RatingWrapper>
              <Rating>{score}</Rating>

              <StyledRate
                size={30}
                disabled={true}
                value={score}
                onChange={newScore => {
                  if (newScore === score) {
                    return;
                  }
                  const scoreToSave = newScore !== 0 ? String(newScore) : null;
                  saveScore(scoreToSave);
                }}
              />
            </RatingWrapper>
            {project.comment && !isEditingComment && (
              <>
                <Annotation>Comment:</Annotation>
                <Comment>{project.comment}</Comment>
                <Annotation
                  style={{ marginTop: '0', cursor: 'pointer' }}
                  onClick={() => setIsEditingComment(true)}
                >
                  <u>Edit comment</u>
                </Annotation>
              </>
            )}
            {(!project.comment || isEditingComment) && (
              <>
                <TextArea
                  placeholder="Please enter your comment here..."
                  value={commentValue}
                  onChange={e => setCommentValue(e.target.value)}
                />
                <TextAreaButton disabled={isFetchingComment} onClick={() => saveComment(commentValue)}>
                  Save{' '}
                  {isFetchingComment && (
                    <span>
                      <i className="fa fa-circle-o-notch fa-spin" />
                    </span>
                  )}
                </TextAreaButton>
              </>
            )}
          </>
        )}

        <DescriptionWrapper>
          {project.description && <Description>{project.description}</Description>}

          {project.participantCategory && (
            <>
              <DetailTitle>Participant category: </DetailTitle>
              <span>{getParticipantCategory(project.participantCategory)}</span>
              <br />
            </>
          )}

          {project.company && (
            <>
              <DetailTitle>Company name: </DetailTitle>
              <span>{project.company}</span>
              <br />
            </>
          )}

          {project.location && (
            <>
              <DetailTitle>Location: </DetailTitle>
              <span>{project.location}</span>
              <br />
            </>
          )}

          {project.designTeam && (
            <>
              <DetailTitle>Team: </DetailTitle>
              <span>{project.designTeam}</span>
            </>
          )}
        </DescriptionWrapper>

        <NavigationButtonsWrapper>
          {project.previousProjectId && (
            <NextButton
              onClick={() => {
                history.push(`/projects/${project.previousProjectId}/${category}`);
                history.go(0);
              }}
            >
              Previous project
            </NextButton>
          )}

          {project.nextProjectId && (
            <NextButton
              onClick={() => {
                history.push(`/projects/${project.nextProjectId}/${category}`);
                history.go(0);
              }}
            >
              Next project
            </NextButton>
          )}
        </NavigationButtonsWrapper>
      </Details>
      <Images>
        {project.images.map(({ image }) => (
          <ProjectImage
            onClick={() => setModalPicture(`${mediaBase}${image}`)}
            key={image}
            src={`${mediaBase}${image}`}
          />
        ))}
      </Images>
      {modalPicture && (
        <PhotoModal
          isOpen={Boolean(modalPicture)}
          closeModal={() => setModalPicture(null)}
          picture={modalPicture}
        />
      )}
    </Wrapper>
  );
};
