import React from "react";
import styled from "styled-components";

import { PrimaryButton } from "components/common/Buttons";
import { AuthContext } from "contexts/AuthContext";
import { useHistory } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: auto auto;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Title = styled.h2``;

const Paragraph = styled.p`
  font-size: 18px;
`;

const Button = styled(PrimaryButton)`
  margin-top: 16px;
`;

export const Homepage: React.FC = () => {
  const { state } = React.useContext(AuthContext);

  const history = useHistory();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <Title>Welcome {state.user.fullName}</Title>

      <Paragraph>
        Jury deliberation
        <br />
        deadline: <u>10.06.2024</u>
      </Paragraph>

      <Button onClick={() => history.push("/projects")}>{"Let's vote"}</Button>
    </Wrapper>
  );
};
