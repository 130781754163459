import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { colors, mediaQueries } from 'variables';

import slxLogo from 'assets/icons/SLX_logo.svg';

const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  padding: 48px 24px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 48px;

  ${mediaQueries.lg} {
    flex-direction: row;
  }
`;

const SecondaryParagraph = styled.p`
  color: ${colors.secondary};
  font-size: '0.9rem';
`;

const Link = styled.a`
  color: ${colors.primary};
`;

const EmailLink = styled(Link)`
  text-decoration: none;
`;

const Copyright = styled.span`
  ${mediaQueries.lg} {
    margin: 0 12px;
  }
`;

export const Footer: React.FC = () => {
  const location = useLocation();

  return (
    <Wrapper>
      {location.pathname === '/' && (
        <>
          <SecondaryParagraph>Powered by:</SecondaryParagraph>
          <img src={slxLogo} />
        </>
      )}

      <TextWrapper>
        <EmailLink href="mailto: hello@designeducates.com">hello@designeducates.com</EmailLink>
        <Copyright>© 2022 by Laka Foundation.</Copyright>

        <Link
          href="https://designeducates.com/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy and Cookie Policy
        </Link>
      </TextWrapper>
    </Wrapper>
  );
};
